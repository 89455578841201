// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
    /** success **/
    --ion-color-success-translucent: #f0faf4;

    /** danger **/
    --ion-color-danger-translucent: #f9f1f2;

    /** cancel **/
    --cancel-color: #868383;

    /** validate **/
    --validate-color: #3880FF;

    /** floating label **/
    --floating-color: #92949C;
}
